import React from 'react'

const DonateButtonComponent = () => {
  const donateLink = () => {
    window.location.href = "https://buymeacoffee.com/zobirofkird";
  }
  return (
    <div className='dark:hidden'>
        <svg
          fill="#000000" 
          height="48px" 
          width="48px" 
          version="1.1" 
          id="Layer_1" 
          viewBox="0 0 333 333" 
          className="size-10 fixed bottom-0 right-0 m-5 dark:bg-white dark:rounded cursor-pointer"
          onClick={donateLink}
        >
          <g>
            <path d="M305.5,51.224h-278C12.336,51.224,0,63.56,0,78.724v79.68c0,15.164,12.336,27.5,27.5,27.5h207.299v21.178c-14.278,0-22.698,10.174-22.698,21.251c0,10.662,8.002,21.25,22.698,21.25c0,9.659,0,14.799,0,24.694c0,4.142,3.358,7.5,7.5,7.5h67.062c4.142,0,7.5-3.358,7.5-7.5c0-10.426,0-43.821,0-53.818c0-13.761-11.195-24.956-24.957-24.956H277.3v-9.598h28.2c15.164,0,27.5-12.336,27.5-27.5v-79.68C333,63.56,320.664,51.224,305.5,51.224z M301.86,266.776h-52.062v-7.713h52.062V266.776z M291.904,210.5v0.001c5.49,0,9.957,4.466,9.957,9.956v23.605h-52.062v-1.981c0-4.142-3.358-7.5-7.5-7.5h-8.948c-3.446,0-6.25-2.804-6.25-6.25s2.804-6.251,6.25-6.251h8.948c4.142,0,7.5-3.358,7.5-7.5c0-9.412,0-36.592,0-45.929c0-3.446,2.804-6.251,6.25-6.251c3.446,0,6.25,2.804,6.25,6.251c0,20.295,0,14.185,0,34.349c0,4.142,3.358,7.5,7.5,7.5H291.904z M318,158.404c0,6.893-5.607,12.5-12.5,12.5h-28.201v-2.251c0-11.718-9.533-21.251-21.25-21.251c-11.717,0-21.25,9.533-21.25,21.251v2.251H27.5c-6.893,0-12.5-5.607-12.5-12.5v-79.68c0-6.893,5.607-12.5,12.5-12.5h278c6.893,0,12.5,5.607,12.5,12.5V158.404z"></path>
            <path d="M63.762,97.102h-6.864c-1.803,0-3.199,1.396-3.199,3.199v34.318c0,1.745,1.396,3.199,3.199,3.199h6.864c11.226,0,20.3-9.132,20.3-20.417C84.062,106.234,74.988,97.102,63.762,97.102z M63.762,131.362h-3.606v-27.804h3.606c6.027,0,10.898,4.87,10.898,10.897C74.66,126.492,69.789,131.362,63.762,131.362z"></path>
          </g>
        </svg>
    </div>

  )
}

export default DonateButtonComponent